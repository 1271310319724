import React, { useEffect, useMemo, useState } from 'react';
import { FiTrash, FiEdit3 } from 'react-icons/fi';
import { GoPlus } from 'react-icons/go';
import { GrDrag } from 'react-icons/gr';
import SortableTree, {
  isDescendant, removeNodeAtPath, defaultGetNodeKey,
} from 'react-sortable-tree';
import classnames from 'classnames';
import Api from '../../../services/api';
import Select from '../Select';

export default function Chapters({ chapters, mediaItems, mediaItem }) {
  const [items, setItems] = useState(chapters || []);
  const selectOptions = useMemo(() => mediaItems.map((item) => ({ value: item.id, label: item.title })), [mediaItems]);

  useEffect(() => {
    Api.admin.media_items.save_chapters_order({ items, id: mediaItem.id })
      .then(() => console.log('Chapters updated'))
      .catch((e) => console.error('Error. Chapters not updated', e.message));
  }, [items]);

  const handleSelect = (id) => {
    const selectedItem = mediaItems.find((i) => i.id === id);
    if (items.find((i) => i.id === id)) return;

    setItems([...items, selectedItem]);
  };

  const renderChapters = () => {
    if (items.length === 0) {
      return (
        <div className="modules-placeholder">
          <span>No chapters added yet</span>
        </div>
      );
    }

    return (
      <div className="modules">
        { items.length > 0 && (
          <div className="modules-tree">
            <SortableTree
              maxDepth={1}
              treeData={items}
              onChange={setItems}
              isVirtualized={false}
              scaffoldBlockPxWidth={10}
              rowHeight={40}
              getNodeKey={defaultGetNodeKey}
              nodeContentRenderer={({ // eslint-disable-line react/no-unstable-nested-components
                connectDragPreview,
                connectDragSource,
                isDragging,
                canDrop,
                canDrag,
                node,
                draggedNode,
                className,
                style,
                didDrop,
                path,
                ...otherProps
              }) => {
                let handle;
                if (canDrag) {
                  handle = connectDragSource(<div className="drag-handle"><GrDrag /></div>, {
                    dropEffect: 'copy',
                  });
                }

                const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
                const isLandingPadActive = !didDrop && isDragging;

                return (
                  <div style={{ height: '100%', borderBottom: '1px solid #d3d3d3' }} {...otherProps}>
                    <div className="rst__rowWrapper">
                      {connectDragPreview(
                        <div
                          className={classnames(
                            'rst__row',
                            isLandingPadActive && 'rst__rowLandingPad',
                            isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                            className,
                          )}
                          style={{
                            opacity: isDraggedDescendant ? 0.5 : 1,
                            ...style,
                          }}
                        >
                          {handle}
                          <div className="module chapter">
                            <p className="module-name chapter-title">
                              {node.title}
                            </p>
                            <button
                              type="button"
                              className="edit-button"
                              onClick={() => {
                                window.open(`/admin/media_items/${node.id}/edit`, '_blank');
                                return null;
                              }}
                            >
                              <FiEdit3 />
                            </button>
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() => {
                                setItems((treeData) => removeNodeAtPath({
                                  treeData,
                                  path,
                                  getNodeKey: defaultGetNodeKey,
                                }));
                              }}
                            >
                              <FiTrash />
                            </button>
                          </div>
                        </div>,
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="admin-page react-admin-academy-modules chapters-container">
      <div className="header chapter-header">
        <h1>eBook chapters</h1>
        <button
          type="button"
          className="button"
          onClick={() => {
            window.open('/admin/media_items/new', '_blank');
            return null;
          }}
        >
          Create New Media Item
          <GoPlus />
        </button>
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <Select
          onChange={(i) => handleSelect(i.value)}
          options={selectOptions || []}
          isSearchable
          placeholder="Search by Media Item title"
        />
      </div>
      {renderChapters()}
    </div>
  );
}
