import React from 'react';
import Logo from '../../../../assets/images/logo-white.svg';

export default function Navbar() {
  const location = window.location.pathname;

  return (
    <nav className="react-admin-navbar">
      <a href="/" className="logo">
        <img alt="logo" src={Logo} />
      </a>
      <div className="separator" />
      <div>
        <a href="/admin/dashboard" className={`link ${location.startsWith('/admin/dashboard') && 'active'}`}>
          Dashboard
        </a>
        <a href="/admin/accounts" className={`link ${location.startsWith('/admin/accounts') && 'active'}`}>
          Accounts
        </a>
        <a href="/admin/users" className={`link ${location.startsWith('/admin/users') && 'active'}`}>
          Users
        </a>
        <a href="/admin/user_licenses" className={`link ${location.startsWith('/admin/user_licenses') && 'active'}`}>
          User Licenses
        </a>
      </div>
      <div className="separator" />
      <div>
        <div className="section-title">Control Items</div>
        <a href="/admin/case_digests" className={`link ${location.startsWith('/admin/case_digests') && 'active'}`}>
          Case Law
        </a>
        <a href="/admin/rules" className={`link ${location.startsWith('/admin/rules') && 'active'}`}>
          Rules
        </a>
        <a href="/admin/categories" className={`link ${location.startsWith('/admin/categories') && 'active'}`}>
          Categories
        </a>
        <a href="/admin/checklists" className={`link ${location.startsWith('/admin/checklists') && 'active'}`}>
          Checklists
        </a>
        <a href="/admin/templates" className={`link ${location.startsWith('/admin/templates') && 'active'}`}>
          Forms
        </a>
        <a href="/admin/glossary_terms" className={`link ${location.startsWith('/admin/glossary_terms') && 'active'}`}>
          Glossary Terms
        </a>
        <a href="/admin/academy" className={`link ${location.startsWith('/admin/academy') && 'active'}`}>
          Academy
        </a>
        <a href="/admin/tags" className={`link ${location.startsWith('/admin/tags') && 'active'}`}>
          Issues
        </a>
        <a href="/admin/issue_categories" className={`link ${location.startsWith('/admin/issue_categories') && 'active'}`}>
          Issue Categories
        </a>
        <a href="/admin/courts" className={`link ${location.startsWith('/admin/courts') && 'active'}`}>
          Courts
        </a>
        <a href="/admin/reports" className={`link ${location.startsWith('/admin/reports') && 'active'}`}>
          Reports
        </a>
        <a href="/admin/referral_codes" className={`link ${location.startsWith('/admin/referral_codes') && 'active'}`}>
          Referral Codes
        </a>
      </div>
    </nav>
  );
}
